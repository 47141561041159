<template>
    <div class="bg-white darky">
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @goToUpdateOrder="toUpdate($event)"
            @packageTracking="toPackageTracking($event)"
            @goToUpdateStep="toUpdateStep($event)"
            @importFile="toImportFile()"
            @goToUpdateRecurrent="toUpdateRecurrent($event)"
            @duplicateRecurrent="duplicateRecurrent($event)"
        />
    </div>
</template>
<script>
import NavTabs from '@/components/elements/NavTabs.vue'
import { mapActions, mapState } from 'pinia'
// import Orders from '@/views/orders/List.vue'
import Orders from '@/views/orders/BecycleList.vue'
import PaymentResult from '@/views/orders/PaymentResult.vue'
import RecurrentOrders from '@/views/orders/RecurrentOrders.vue'
import UnpaidOrders from '@/views/orders/UnpaidOrders.vue'

import NewOrder from '@/views/orders/CreateOrUpdate.vue'
import UpdateStep from '@/views/orders/UpdateStep.vue'
// import ImportOrders from '@/views/orders/ImportOrders.vue'
import Articles from '@/views/orders/ArticlesList.vue'
import ImportedOrders from '@/views/orders/ImportedOrders.vue'
import PackageTracking from '@/views/orders/PackageTracking.vue'

import Containers from '@/views/orders/ContainersList.vue'
import OrderStepCorrection from '@/views/orders/OrderStepCorrection.vue'
import Refunds from '@/views/orders/RefundsList.vue'

import keys from '@/resources/keys'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useOrderStore } from '@/stores/orderStore'
import { useRecurrentOrderStore } from '@/stores/recurrentOrderStore'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'

export default {
    name: 'OrdersWrapper',
    components: {
        NavTabs,
        Orders,
        NewOrder,
        PackageTracking,
        ImportedOrders,
        UpdateStep,
        OrderStepCorrection,
        Containers,
        RecurrentOrders,
        UnpaidOrders,
        PaymentResult,
        Refunds,
        Articles,
    },
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]

        return { key }
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name,
            ),
            // tabs: [
            //     ['list-ul', 'orders', true],
            //     ['list-ul', 'orders', true],

            //     ['cart-plus', 'new_order', true],
            //     ['pen', 'order_step_correction'],
            //     // ['file-import', 'import_orders'],
            //     ['file-arrow-up', 'imported_orders'],
            //     ['box', 'containers', true],
            // ],
        }
    },

    created() {
        this.$cookies.remove('filtered')
        this.$cookies.remove('unpaidFiltered')
        this.$cookies.remove('filteredArticles')
        this.$cookies.remove('recurrentFiltered')
        this.$cookies.remove('importedFiltered')
        this.$cookies.remove('refundsFiltered')

        const isB2CInterval = setInterval(() => {
            if (this.isB2C !== null) {
                clearInterval(isB2CInterval)
                if (this.isB2C === true) {
                    this.fetchOneClient(this.user.idClient)
                }
                if (this.$route.name === 'my_cart') {
                    this.changeContent('orders')
                } else if (
                    this.$route.params.id &&
                    this.$route.name === 'update_order'
                ) {
                    this.toUpdate(this.$route.params.id)
                } else if (
                    this.$route.params.id &&
                    this.$route.name === 'update_recurrent'
                ) {
                    this.toUpdateRecurrent(this.$route.params.id)
                } else if (
                    this.$route.params.id &&
                    this.$route.name === 'update_step'
                ) {
                    this.toUpdateStep({
                        id: this.$route.params.id,
                        order_ref: this.$route.params.order_ref,
                    })
                } else if (this.$route.name === 'new_order') {
                    this.toCreate()
                } else if (
                    this.$route.name === 'new_container' ||
                    this.$route.name === 'update_container'
                ) {
                    this.$router.push({ name: 'containers' })
                    this.changeContent('containers')
                } else if (this.$route.name === 'packages_tracking') {
                    const params = {
                        orderClient: this.$route.params.orderClientId,
                        delivery: this.$route.params.deliveryId,

                        reference: this.$route.params.packageRef, // package
                    }
                    this.toPackageTracking(params)
                } else if (this.$route.name === 'one_package_tracking') {
                    this.toPackageTracking(this.$route.params.packageRef)
                } else {
                    this.changeContent(this.$route.name)
                }
            }
        }, 200)
    },
    updated() {
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
                this.$cookies.remove('unpaidFiltered')
                this.$cookies.remove('recurrentFiltered')
                this.$cookies.remove('importedFiltered')
                this.$cookies.remove('refundsFiltered')
                this.$cookies.remove('filteredArticles')
            }

            if (this.sideBarNav === 'new_order') {
                this.toCreate()
            } else {
                this.changeContent(this.sideBarNav)
            }
        }
    },

    computed: {
        ...mapState(useUserStore, {
            user: 'current',
            isB2C: 'isB2C',
        }),
    },
    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(useClientStore, {
            fetchOneClient: 'fetchOne',
            fetchClientContacts: 'fetchClientContacts',
        }),
        ...mapActions(useOrderStore, [
            'fetchStep',
            'fetchOne',
            'fetchOneDelivery',
            'trackOnePackage',
        ]),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
        }),
        ...mapActions(useClientStore, {
            fetchOneCLient: 'fetchOne',
        }),
        ...mapActions(useVehicleStore, {
            searchVehicles: 'search',
        }),
        ...mapActions(useRecurrentOrderStore, [
            'fetchOneRecurrent',
            'duplicateRecurrentOrder',
        ]),
        toUpdateStep(step) {
            this.$emit('removeSidebarNav')

            this.fetchStep({
                id: step.id,
                key: this.key,
            }).then((res) => {
                this.componentToDisplay = this.humanize('update_step')
                this.$router.push({
                    name: 'update_step',
                    params: {
                        id: step.id,
                        order_ref: res.data.Order.OrderClient.Reference,
                    },
                })
            })
        },
        changeContent(tab) {
            this.$emit('removeSidebarNav')
            this.componentToDisplay = this.humanize(tab)
        },
        toPackageTracking(params) {
            this.$emit('removeSidebarNav')

            if (params.orderClientId) {
                this.componentToDisplay = this.humanize('package_tracking')
                this.$router.push({
                    name: 'packages_tracking',
                    params: {
                        orderClient: params.orderClientId,
                        delivery: params.deliveryId,

                        reference: params.packageRef, // package
                    },
                })
            } else if (
                this.$cookies.get('currentTrackedPackageSteps') === null
            ) {
                this.$router.push({
                    name: 'orders',
                })
                this.componentToDisplay = this.humanize('orders')
            } else {
                this.trackOnePackage(
                    params,
                    this.$cookies.get('currentTrackedPackageSteps'),
                ).then(() => {
                    this.$router.push({
                        name: 'one_package_tracking',
                        params: {
                            packageRef: params,
                        },
                    })
                    this.componentToDisplay = this.humanize('package_tracking')
                })
            }
        },
        toCreate() {
            this.$emit('removeSidebarNav')
            this.componentToDisplay = this.humanize('new_order')
            this.$router.push({ name: 'new_order' })
        },
        duplicateRecurrent(id) {
            this.$emit('removeSidebarNav')
            this.duplicateRecurrentOrder(id).then(() => {
                this.componentToDisplay = this.humanize('new_order')
                this.$router.push({ name: 'new_order' })
            })
        },

        toImportFile() {
            this.$cookies.remove('filtered')

            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize('import_orders')
            this.$router.push({ name: 'import_orders' })
        },
        toUpdateRecurrent(id) {
            const vehiclePayload = {
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Type',
                sortDirection: 'ASC',
            }
            // const driversPayload = {
            //     query: [],
            //     filter: [{ dbField: 'Active', value: 1 }],
            //     perPage: 100000000000,
            //     page: 1,
            //     sortField: 'LastName',
            //     sortDirection: 'ASC',
            // }

            // this.searchDrivers(driversPayload)
            // this.availableDriversForDate(date).then((res) => {
            //     console.log(res)
            //     if (res && res.status === 200) {
            //         res.data.Records.forEach((driver) => {
            //             const name = `${driver.Firstname} ${driver.Lastname}`
            //             const option = {
            //                 name,
            //                 value: +driver.Id,
            //             }
            //             this.driverOptions.push(option)
            //         })
            //     }
            // })
            this.searchVehicles(vehiclePayload)
            this.fetchOneRecurrent(id)
            this.$router.push({ name: 'update_recurrent', params: { id } })
            this.componentToDisplay = this.humanize('new_order')
        },
        toUpdate(id) {
            if (this.$cookies.get('currentDeliveryId'))
                this.$cookies.remove('currentDeliveryId')
            this.$emit('removeSidebarNav')
            if (this.isB2C === false) {
                const vehiclePayload = {
                    query: [],
                    filter: [],
                    perPage: 10000,
                    page: 1,
                    sortField: 'Type',
                    sortDirection: 'ASC',
                }
                // const driversPayload = {
                //     query: [],
                //     filter: [{ dbField: 'Active', value: 1 }],
                //     perPage: 100000000000,
                //     page: 1,
                //     sortField: 'LastName',
                //     sortDirection: 'ASC',
                // }

                // this.searchDrivers(driversPayload)
                //     this.availableDriversForDate(date).then((res) => {
                //     console.log(res)
                //     if (res && res.status === 200) {
                //         res.data.Records.forEach((driver) => {
                //             const name = `${driver.Firstname} ${driver.Lastname}`
                //             const option = {
                //                 name,
                //                 value: +driver.Id,
                //             }
                //             this.driverOptions.push(option)
                //         })
                //     }
                // })
                this.searchVehicles(vehiclePayload)
                this.fetchOne(id)
            } else {
                this.fetchOneClient(this.user.idClient)
                this.fetchOneDelivery(id)
            }
            this.$router.push({ name: 'update_order', params: { id } })
            this.componentToDisplay = this.humanize('new_order')
        },

        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
