<template>
    <!--Sidebar with Dimmer -->
    <!-- <div class="fixed inset-0 flex mr-6"> -->
    <!-- Sidebar -->
    <aside style="width: 3.5rem" class="flex top-0 fixed h-full z-40 left-0">
        <div
            ref="content"
            class="bg-zinc-900 text-zinc-300 overflow-hidden flex pt-3 justify-center"
        >
            <div
                id="sidebarMenu"
                class="mb-4 px-2 flex items-center flex-col"
                :key="refresh"
            >
                <img
                    alt="company logo"
                    class="sm mb-8 w-7"
                    :src="`/logo/${userStore.logo}header-nobg.png`"
                    :class="userStore.logo === 'becycle' ? 'mt-1 ' : ''"
                />

                <div v-for="(menu, index) in menus" :key="index">
                    <!-- Main sidebar items -->
                    <div
                        v-if="menu.canAccess === true"
                        class="flex w-full items-center justify-between"
                    >
                        <ul>
                            <span
                                class="cursor-pointer mt-6"
                                v-if="
                                    (userStore.isB2C === false &&
                                        !menu.onlyExtra) ||
                                    (userStore.isB2C === true &&
                                        menu.B2C === true)
                                "
                            >
                                <li
                                    @click="showTabs(menu)"
                                    @mouseenter="
                                        showTooltip(
                                            'tooltip-right' + index,
                                            true
                                        )
                                    "
                                    @mouseleave="
                                        showTooltip(
                                            'tooltip-right' + index,
                                            false
                                        )
                                    "
                                >
                                    <!-- :title="$t(menu.name)" -->
                                    <!-- :class="menu.tiny_icon ? 'ml-1' : ''" -->
                                    <div
                                        class="py-2 w-10"
                                        :id="`sidebar-${menu.name}`"
                                        :style="
                                            menu.style
                                                ? 'margin-left: 0.7px;'
                                                : ''
                                        "
                                        :class="
                                            menu.tabs ? 'rounded-t' : 'rounded'
                                        "
                                    >
                                        <!-- faire la classe avec un booleen -->
                                        <span
                                            v-if="menu.layer"
                                            :id="`icon-${menu.name}`"
                                            class="fa-fw fa-sm"
                                        >
                                            <fa-layer>
                                                <fa-icon
                                                    :icon="menu.iconA"
                                                ></fa-icon>

                                                <fa-icon
                                                    style="font-weight: 200"
                                                    transform="shrink-6 up-10 right-14"
                                                    :icon="menu.iconB"
                                                ></fa-icon>
                                                <!-- transform="shrink-6" -->
                                            </fa-layer>
                                        </span>

                                        <fa-icon
                                            v-else
                                            :id="`icon-${menu.name}`"
                                            :icon="menu.icon"
                                            class="fa-sm"
                                        ></fa-icon>
                                        <span
                                            :id="'tooltip-right' + index"
                                            role="tooltip"
                                            class="absolute invisible w-32 mb-3 z-10 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                        >
                                            {{ $t(menu.name) }}
                                        </span>
                                    </div>
                                </li>
                            </span>

                            <!-- <li
                                v-if="
                                    (isB2C === false && !menu.onlyExtra) ||
                                    (isB2C === true && menu.B2C === true)
                                "
                                class="mt-6"
                            >
                                <router-link :to="{ name: menu.name }">
                                    <icon-button
                                        :class="iconClasses(menu)"
                                        :title="$t(menu.name)"
                                        :icon="menu.icon"
                                        :customButtonStyle="true"
                                        :iconStyle="iconStyle(menu)"
                                        @click="showTabs(menu)"
                                    />
                                </router-link> -->
                            <span
                                v-if="shownTab[menu.name] === true"
                                class="w-full"
                            >
                                <ul
                                    v-for="(tab, index) in menu.tabs"
                                    :key="index"
                                    class="space-x-2"
                                >
                                    <span
                                        class="cursor-pointer"
                                        v-if="canSeeMenu(tab) === true"
                                        @click="navigateToSub(tab, menu)"
                                    >
                                        <li
                                            @mouseenter="
                                                showTooltip(
                                                    'sub-tooltip-right' + index,
                                                    true
                                                )
                                            "
                                            @mouseleave="
                                                showTooltip(
                                                    'sub-tooltip-right' + index,
                                                    false
                                                )
                                            "
                                        >
                                            <div
                                                :id="`sub-sidebar-${tab.name}`"
                                                class="p-2 themed-subsidebar-bg"
                                                :class="
                                                    index ===
                                                    menu.tabs.length - 1
                                                        ? 'rounded-b'
                                                        : ''
                                                "
                                            >
                                                <span
                                                    v-if="tab.layer"
                                                    :id="`sub-icon-${tab.name}`"
                                                    class="fa-fw"
                                                >
                                                    <fa-layer>
                                                        <fa-icon
                                                            :icon="tab.iconA"
                                                            class="fa-sm"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="tab.iconB"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                </span>
                                                <fa-icon
                                                    v-else
                                                    :id="`sub-icon-${tab.name}`"
                                                    :icon="tab.icon"
                                                    class="fa-sm"
                                                ></fa-icon>
                                                <span
                                                    :id="
                                                        'sub-tooltip-right' +
                                                        index
                                                    "
                                                    role="tooltip"
                                                    class="absolute w-32 invisible z-10 mb-3 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                                >
                                                    {{ $t(tab.name) }}
                                                </span>
                                            </div>
                                        </li>
                                    </span>
                                </ul>
                            </span>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </aside>
    <!-- </div> -->
</template>

<script>
import checkAccess from '@/resources/accessChecker'
import { useUserStore } from '@/stores/userStore'
import { mapStores } from 'pinia'

export default {
    name: 'SideBar',
    components: {},

    mounted() {
        // const userStoreInterval = setInterval(() => {
        //     if (this.userStore.isB2C ) {
        //         clearInterval(userStoreInterval)
        this.menus.forEach((menu) => {
            if (
                menu.name === this.$route.name ||
                (menu.name === 'settings' &&
                    this.$route.path.includes('settings'))
            ) {
                this.showTabs(menu)
            } else if (menu.tabs) {
                menu.tabs.forEach((tab) => {
                    if (tab.name === this.$route.name) {
                        this.navigateToSub(tab, menu)
                        this.showTabs(menu, false)
                    }
                })
                if (
                    !menu.tabs.includes(this.$route.name) &&
                    this.$route.path.includes(menu.name)
                ) {
                    this.showTabs(menu, false)
                }
            }
            this.setAccesses(menu)
            this.refresh += 1
        })
        // }
        // }, 30)
    },
    computed: {
        ...mapStores(useUserStore),

        menus() {
            const menus = [
                { icon: 'chart-line', name: 'dashboard', B2C: false },
                {
                    icon: 'address-book',
                    name: 'delivery_addresses',
                    B2C: true,
                    style: true,
                    onlyExtra: true,
                },
                {
                    icon: 'people-group',
                    name: 'clients',
                    tabs: [
                        // { icon: 'list-ul', name: 'clients', all: false },
                        {
                            icon: 'address-book',
                            name: 'delivery_addresses',
                            all: false,
                        },
                        {
                            icon: 'comments',
                            name: 'contact_client_history',
                            all: false,
                        },
                        {
                            icon: 'file-signature',
                            name: 'contracts',
                            all: false,
                        },
                    ],
                },
                {
                    icon: 'cart-shopping',
                    name: 'orders',
                    B2C: true,
                    tabs: [
                        {
                            iconA: 'cart-shopping',
                            iconB: 'repeat',
                            name: 'recurrent_orders',
                            all: false,
                            layer: true,
                        },

                        // { icon: 'list-ul', name: 'orders', all: true },
                        { icon: 'cart-plus', name: 'new_order', all: true },
                        {
                            iconA: 'euro-sign',
                            iconB: 'hourglass',
                            layer: true,
                            name: 'unpaid_orders',
                            all: false,
                        },
                        {
                            icon: 'hand-holding-dollar',
                            name: 'refunds',
                            all: false,
                        },
                        {
                            icon: 'pen',
                            name: 'order_step_correction',
                            all: false,
                        },
                        // {
                        //     icon: 'file-import',
                        //     name: 'import_orders',
                        //     all: false,
                        // },
                        {
                            icon: 'file-export',
                            name: 'imported_orders',
                            all: false,
                        },
                        { icon: 'box', name: 'containers', all: true },
                        {
                            icon: 'basket-shopping',
                            name: 'articles',
                            all: false,
                        },
                    ],
                },
                {
                    iconA: 'route',
                    iconB: 'clock',
                    name: 'daily_view',
                    layer: true,
                },
                { icon: 'road', name: 'simulations' },

                {
                    icon: 'route',
                    name: 'tours',
                    // tabs: [
                    // { icon: 'list-ul', name: 'tours' },
                    // ['cart-plus', 'new_order'],
                    // { icon: 'road', name: 'simulations' },
                    // {
                    //     icon: 'calendar-day',
                    //     name: 'daily_view',

                    // },
                    // {
                    //     icon: 'clock-rotate-left',
                    //     name: 'past_map',
                    // },
                    // ],
                },

                {
                    // iconA: 'user',
                    // iconB: 'truck',
                    // layer: true,
                    icon: 'people-carry-box',
                    name: 'drivers',
                    tabs: [
                        // { icon: 'list-ul', name: 'drivers' },
                        { icon: 'calendar', name: 'weekly_planning' },
                    ],
                },
                {
                    icon: 'file-invoice-dollar',
                    name: 'my_invoices',
                    style: true,
                    B2C: true,
                    onlyExtra: true,
                },
                {
                    icon: 'file-invoice-dollar',
                    name: 'invoicings',
                    // name: this.userStore.isB2C === false ? 'billings':  'bills',
                    // tiny_icon: true,
                    // // B2C: true,
                    // B2C: false,

                    tabs: [
                        // { icon: 'list-ul', name: 'drivers' },
                        {
                            iconB: 'envelope',
                            iconA: 'file-invoice-dollar',
                            layer: true,
                            // icon: 'file-invoice-dollar',
                            all: false,

                            name: 'invoices',
                        },
                        {
                            iconB: 'hourglass',
                            iconA: 'file-invoice-dollar',
                            layer: true,
                            // icon: 'file-invoice-dollar',
                            all: false,

                            name: 'unpaid_invoices',
                        },
                    ],
                },
                {
                    icon: 'truck',
                    name: 'vehicles',
                    tabs: [{ icon: 'wrench', name: 'maintenances_list' }],
                },
                { icon: 'boxes-packing', name: 'suppliers' },
                {
                    icon: 'street-view',
                    name: 'areas',
                    tiny_icon: true,
                },
                {
                    icon: 'users',
                    name: 'users',
                    tabs: [
                        // { icon: 'list-ul', name: 'drivers' },
                        { icon: 'masks-theater', name: 'roles_list' },
                        { icon: ['fab', 'grav'], name: 'universes_list' },
                    ],
                },
                {
                    icon: 'sliders',
                    name: 'settings',
                    // tabs: [
                    //     // { icon: 'list-ul', name: 'drivers' },
                    //     {
                    //         // layered: true,
                    //         // icons: ['cart-shopping', 'sliders'],
                    //         name: 'orders_settings',
                    //         icon: 'cart-shopping',
                    //     },
                    // {
                    //     // layered: true,
                    //     // icons: ['cart-shopping', 'sliders'],
                    //     name: 'orders_settings',
                    //     icon: 'bell',
                    // },
                    // {
                    //     // layered: true,
                    //     // icons: ['cart-shopping', 'sliders'],
                    //     name: 'orders_settings',
                    //     icon: 'file-invoice-dollar',
                    // },
                    // ],
                },
            ]
            if (
                this.userStore.current &&
                this.userStore.current.type === 'driver' &&
                checkAccess(
                    this.userStore,

                    this.userStore.userAuthorisations
                )
            ) {
                menus.push({
                    icon: 'calendar',
                    name: 'driver_planning',
                    id: this.userStore.current.idDriver,
                })
            }
            return menus
        },
    },
    data() {
        return {
            open: false,
            dimmer: true,
            refresh: 0,
            subTabSelected: false,
            right: false,
            shownTab: {
                orders: false,
                drivers: false,
                tours: false,
                clients: false,
                users: false,
                invoicings: false,
                vehicles: false,
            },
        }
    },
    watch: {
        $route(val, old) {
            this.$emit('sidebarNavigateFromRoute', true)
            this.menus.forEach((menu) => {
                if (
                    menu.name === val.name ||
                    (menu.name === 'settings' && val.path.includes('settings'))
                ) {
                    this.showTabs(menu)
                } else if (menu.tabs) {
                    menu.tabs.forEach((tab) => {
                        if (tab.name === val.name) {
                            this.navigateToSub(tab, menu)
                            this.showTabs(menu, false)
                        }
                    })
                    if (
                        !menu.tabs.includes(this.$route.name) &&
                        this.$route.path.includes(menu.name)
                    ) {
                        this.showTabs(menu, false)
                    }
                }
                this.setAccesses(menu)
                this.refresh += 1
            })
        },
    },
    methods: {
        showTooltip(elId, hovered) {
            const tooltip = document.getElementById(elId)
            if (hovered === false) {
                tooltip.classList.add('invisible')
            } else {
                tooltip.classList.remove('invisible')
            }
        },
        setAccesses(menu) {
            menu.canAccess = true
            const routeToCheck = this.$router.options.routes.find(
                (r) => r.name === menu.name
            )
            if (routeToCheck) {
                menu.canAccess = checkAccess(
                    this.userStore,

                    routeToCheck.meta.authorisations
                )
            }
            if (menu.tabs) {
                menu.tabs.forEach((tab) => {
                    let tabRoute = null

                    routeToCheck.children.forEach((child) => {
                        if (child.name === tab.name) {
                            tabRoute = child
                        }
                    })
                    // }
                    if (tabRoute) {
                        tab.canAccess = checkAccess(
                            this.userStore,
                            tabRoute.meta.authorisations
                        )
                    }
                    if (
                        menu.canAccess === false &&
                        tab.canAccess === true &&
                        !this.menus.includes(tab)
                    ) {
                        this.menus.push(tab)
                    }
                })
            }
        },
        canSeeMenu(tab) {
            // if (tab.becycle !== undefined) {
            //     if (tab.becycle === true) {
            //         return true
            //     }
            //     return false
            // }
            if (
                ((tab.all && tab.all === true) ||
                    this.userStore.isB2C === false) &&
                tab.canAccess === true
            ) {
                return true
            }

            return false
        },
        showTabs(menu, redirect = true) {
            if (redirect === true) {
                if (menu.id) {
                    this.$router.push({
                        name: menu.name,
                        params: { id: menu.id },
                    })
                } else {
                    this.$router.push({
                        name: menu.name,
                    })
                }
                this.$emit('navigate', menu.name)
            }
            const interval = setInterval(() => {
                if (document.getElementById(`sidebar-${menu.name}`)) {
                    clearInterval(interval)
                    document
                        .getElementById(`sidebar-${menu.name}`)
                        .classList.add('sidebar-icon-menu')
                    if (
                        this.$route.name === menu.name ||
                        (menu.name === 'settings' &&
                            this.$route.path.includes('settings'))
                    ) {
                        document
                            .getElementById(`icon-${menu.name}`)
                            .classList.add('text-zinc-900')
                    } else {
                        document
                            .getElementById(`icon-${menu.name}`)
                            .classList.remove('text-zinc-900')
                    }
                }
            }, 10)

            this.menus.forEach((m) => {
                const inte = setInterval(() => {
                    if (document.getElementById(`sidebar-${m.name}`)) {
                        clearInterval(inte)
                        if (
                            m.name !== menu.name &&
                            m.name !== this.$route.name
                        ) {
                            document
                                .getElementById(`sidebar-${m.name}`)
                                .classList.remove('sidebar-icon-menu')
                            document
                                .getElementById(`icon-${m.name}`)
                                .classList.remove('text-zinc-900')
                        }
                    }
                }, 10)
            })
            if (menu.tabs) {
                Object.keys(this.shownTab).forEach((name) => {
                    if (menu.name === name) {
                        this.shownTab[name] = true
                        const myInterval = setInterval(() => {
                            if (
                                document.getElementById(`sidebar-${menu.name}`)
                            ) {
                                clearInterval(myInterval)

                                document
                                    .getElementById(`sidebar-${menu.name}`)
                                    .classList.add('sidebar-icon-menu')
                            }
                        }, 10)
                        menu.tabs.forEach((menuTab) => {
                            const intervalSub = setInterval(() => {
                                if (
                                    document.getElementById(
                                        `sub-sidebar-${menuTab.name}`
                                    )
                                ) {
                                    clearInterval(intervalSub)
                                    if (menuTab.name === this.$route.name) {
                                        document
                                            .getElementById(
                                                `sub-sidebar-${menuTab.name}`
                                            )
                                            .classList.add('active-sub-tab')
                                        document
                                            .getElementById(
                                                `sub-icon-${menuTab.name}`
                                            )
                                            .classList.add('text-zinc-900')
                                    } else {
                                        document
                                            .getElementById(
                                                `sub-sidebar-${menuTab.name}`
                                            )
                                            .classList.remove('active-sub-tab')
                                        document
                                            .getElementById(
                                                `sub-icon-${menuTab.name}`
                                            )
                                            .classList.remove('text-zinc-900')
                                    }
                                }
                            }, 10)
                        })
                    } else {
                        this.shownTab[name] = false
                    }
                })
            } else {
                this.shownTab = {
                    orders: false,
                    drivers: false,
                    tours: false,
                    clients: false,
                    users: false,
                    invoicings: false,
                    vehicles: false,
                }
            }
        },

        navigateToSub(subTab, menu) {
            this.subTabSelected = false

            this.$router.push({
                name: subTab.name,
            })
            this.$emit('navigate', subTab.name)
            this.subTabSelected = true
            const interval = setInterval(() => {
                if (document.getElementById(`sub-sidebar-${subTab.name}`)) {
                    clearInterval(interval)
                    menu.tabs.forEach((menuTab) => {
                        if (
                            menuTab.name === subTab.name &&
                            this.$route.name === subTab.name
                        ) {
                            document
                                .getElementById(`sub-sidebar-${subTab.name}`)
                                .classList.add('active-sub-tab')
                            document
                                .getElementById(`sub-icon-${subTab.name}`)
                                .classList.add('text-zinc-900')
                        } else if (
                            document.getElementById(
                                `sub-sidebar-${menuTab.name}`
                            )
                        ) {
                            document
                                .getElementById(`sub-sidebar-${menuTab.name}`)
                                .classList.remove('active-sub-tab')
                            document
                                .getElementById(`sub-icon-${menuTab.name}`)
                                .classList.remove('text-zinc-900')
                        }
                    })
                }
            }, 10)
        },
    },
}
</script>
<style scoped>
html {
    background: #efefef;
}
</style>
