<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'package_format'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="articlesList"
                :items="articles"
                :columns="columns"
                :storeToSort="'articles'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateArticle()"
                @clear="clearFilter($event)"
                @update="toUpdateArticle($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
                <!-- @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)" -->
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            :headerText="modalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <div class="mb-3 items-center flex flex-row mt-2 px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('name') + ' *' }}</label
                    >
                    <input
                        v-model="formData.Name"
                        :disabled="editArticle === true"
                        :class="
                            editArticle === true ? 'cursor-not-allowed' : ''
                        "
                        autocomplete="name"
                        @blur="v$.formData.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.formData.Name.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="mb-3 items-center flex flex-row px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('description') + ' *' }}</label
                    >
                    <textarea
                        v-model="formData.Description"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.formData.Description.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div
                    class="grid grid-cols-6 gap-1 p-2 justify-center items-center"
                >
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('ttc')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PriceTTC"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('ht')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PriceHT"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>

                    <div class="col-span-2">
                        <span class="text-sm">
                            {{ $t('vat') }}
                        </span>
                        <div class="vatMS">
                            <Multiselect
                                class="darky"
                                v-model="formData.VAT"
                                :options="vatOptions"
                                :key="refreshMS"
                                :searchable="false"
                                :can-clear="false"
                                :can-deselect="false"
                                :allow-empty="false"
                            >
                            </Multiselect>
                        </div>
                    </div>
                </div>
                <div
                    class="grid grid-cols-8 gap-1 p-2 justify-center items-center"
                >
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('price_pack_ht')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PricePackageHT"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('price_percent')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PricePercent"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>

                    <div class="col-span-2">
                        <span class="text-sm">
                            {{ $t('coef') }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PriceCoefMultiple"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                    <div class="col-span-2">
                        <span class="text-sm">
                            {{ $t('price_written') }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.PriceWritten"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                </div>

                <span
                    class="flex mt-3 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useArticleStore } from '@/stores/articleStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ArticlesList',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            isDefault: false,
            editArticle: false,
            modalHeaderText: 'new_article',
            creating: false,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            show: false,

            articleInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],

            formData: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Description: '',
                PriceHT: '',
                PriceTTC: '',
                VAT: '',
                PricePercent: '',
                PricePackageHT: 0,
                PriceWritten: 0,
                PriceCoefMultiple: '',
            },
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
                Description: { required },
            },
        }
    },
    watch: {
        articles() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        this.isDefault = false
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredArticles')
        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }

        this.setResults()
    },

    unmounted() {
        clearInterval(this.articleInterval)
    },
    computed: {
        articlesActionsAccess() {
            return actionsAccess.orders.articles
        },
        ...mapStores(useUserStore),
        ...mapState(useArticleStore, {
            articles: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
            current: 'current',
        }),
        ...mapStores(useArticleStore),
        vatOptions() {
            let vatOptions = [0, 2.1, 5.5, 10, 20]
            if (
                window.location.host.substring(
                    0,
                    window.location.host.indexOf('.'),
                ) === 'demo01'
            ) {
                vatOptions = [0, 1.05, 1.75, 2.5, 8.5]
            }
            return vatOptions
        },
        columns() {
            return [
                // gérer le islink pour b2C
                {
                    name: 'name',
                    filtrable: true,

                    isLink: checkAccess(
                        this.userStore,
                        this.articlesActionsAccess.update,
                    ),
                    // routeName: 'update_article',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'description',
                    filtrable: false,
                    sortable: false,
                },

                {
                    name: 'ht',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'ttc',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'vat',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'price_pack_ht',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'price_percent',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'coef',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'price_written',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.articlesActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }

            if (
                checkAccess(this.userStore, this.articlesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.articlesActionsAccess.delete)
            ) {
                return [
                    {
                        name: 'delete_selection',
                        action: 'deleteAll',
                        icon: 'trash-can',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.articleInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.articlesActionsAccess.create,
            )
        },

        async deleteOne(article) {
            this.searchingCursor = true

            const response = await this.articleStore.delete(article)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('deleted_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('deleted_ko'))
            }
        },

        toCreateArticle() {
            clearInterval(this.articleInterval)
            // this.$router.push({ name: 'new_container' })
            this.articleStore.$patch({ current: null })
            this.modalHeaderText = 'new_article'
            this.show = true
        },
        toUpdateArticle(article) {
            this.isDefault = false
            clearInterval(this.articleInterval)

            this.modalHeaderText = 'update_article'
            let id = article
            if (article.id) {
                id = article.id
            }

            this.articleStore.$patch({ current: null })
            this.articleStore.fetchOne(id).then(() => {
                const articleInt = setInterval(() => {
                    if (this.current !== null) {
                        clearInterval(articleInt)
                        // if (+this.current.IdClient === 0) {
                        //     this.$toast.warning(
                        //         this.$t('container_not_editable')
                        //     )
                        // } else {
                        this.editArticle = true

                        // this.$router.push({
                        //     name: 'update_article',
                        //     params: { id: this.current.Id },
                        // })
                        this.formData = {
                            Name: this.current.Name,
                            Id: this.current.Id,
                            CreationUserId: this.current.CreationUserId,
                            Description: this.current.Description,
                            PriceHT: this.current.PriceHT,
                            PriceTTC: this.current.PriceTTC,
                            VAT: this.current.VAT,
                            PricePercent: this.current.PricePercent,
                            PricePackageHT: this.current.PricePackageHT,
                            PriceWritten: this.current.PriceWritten,
                            PriceCoefMultiple: this.current.PriceCoefMultiple,
                        }

                        this.show = true
                    }
                    // }
                })
            })
        },
        closeModal() {
            this.isDefault = false

            this.creating = false
            this.v$.$reset()
            this.show = false
            this.editArticle = false
            this.formData = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Description: '',
                PriceHT: '',
                PriceTTC: '',
                VAT: '',
                PricePercent: '',
                PricePackageHT: 0,
                PriceWritten: 0,
                PriceCoefMultiple: '',
            }
            this.setResults()
        },
        async submitModal() {
            this.searchingCursor = true

            this.creating = true
            const isValid = await this.v$.$validate()
            if (isValid === true) {
                if (this.editArticle === true) {
                    this.formData.ModificationUserId = this.userStore.current.id

                    const response = await this.articleStore.update({
                        id: this.formData.Id,
                        form: this.formData,
                    })
                    if (response.status === 200) {
                        this.searchingCursor = false

                        this.$toast.success(this.$t('update_ok'))
                        // this.$router.push({ name: 'articles' })
                        this.setResults()
                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.formData.CreationUserId = this.userStore.current.id

                    const response = await this.articleStore.create(
                        this.formData,
                    )
                    if (response.status === 201) {
                        this.searchingCursor = false

                        this.setResults()

                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.searchingCursor = false

                this.$toast.error(this.$t('form_errors'))
                return false
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.articleStore.delete(el)
            })
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.articleInterval)
            this.$cookies.remove('filteredArticles')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.articleInterval)
            this.$cookies.remove('filteredArticles')
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.articleStore.search(search).then(() => {
                this.searchingCursor = false
                this.refresh += 1
            })
            this.articleInterval = setInterval(() => {
                this.articleStore.search(search)
            }, 20000)

            this.$cookies.set('filteredArticles', search)

            this.refresh += 1
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.articleInterval)
            this.$cookies.remove('filteredArticles')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
<style lang="scss" scoped>
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
</style>
